<template>
  <base-modal-ex
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p
        class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span
          v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i
            class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        Export Report
      </p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <span>Select export format</span>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="is-checkradio"
            :id="reportFormatTypes.excel"
            type="radio"
            :name="reportFormatTypes.excel"
            :value="reportFormatTypes.excel"
            v-model="format">
          <label :for="reportFormatTypes.excel">Microsoft Excel</label>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="is-checkradio"
            :id="reportFormatTypes.word"
            type="radio"
            :name="reportFormatTypes.word"
            :value="reportFormatTypes.word"
            v-model="format">
          <label :for="reportFormatTypes.word">Microsoft Word</label>
        </div>
      </div>
    </section>
    <footer
      class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button
        class="button is-success tooltip"
        @click="exportReport()"
        data-tooltip="Start export"
        :disabled="!format">Export</button>
      <button
        class="button tooltip"
        @click="close()"
        data-tooltip="Close">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import { ReportFormatTypes } from '@/enums'

export default {
  name: 'PrintPreviewExportModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    }
  },
  data() {
    return {
      isActive: false,
      format: ''
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    reportFormatTypes() {
      return ReportFormatTypes
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    exportReport() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('exportReport', this.format)
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>
