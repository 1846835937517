<template>
  <div>
    <print-preview-app-detail-header :show-toggle="false"
      :show-print="false"
      :show-back="!isNewBrowserTab"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div id="pdf-container"
      name="pdf-container"
      :style="`height: calc(100vh - ${toolbarHeight}px); overflow: hidden;`">
      <object id="pdfDoc"
        class="pdf-document"
        :data="pdfUrl"
        type="application/pdf">
        <embed :src="pdfUrl"
          class="pdf-document"
          type="application/pdf">
      </object>
      <!-- <iframe id="pdfDoc"
        src=""
        type="application/pdf"
        style="width: 100%; height: 100%;" /> -->
    </div>
    <print-preview-export-modal v-if="isExportModalActive"
      :active.sync="isExportModalActive"
      @exportReport="exportReport"
      @close="closeExportModal" />
    <vue-email-modal v-if="isEmailModalActive"
      :active.sync="isEmailModalActive"
      icon-name="mdi-email-open"
      icon-type="primary"
      @send="sendEmail"
      @discard="discardEmail"
      @attach="attachMedia"
      @show-attach="showAttachImage"
      :enable-send="enableSend && !isImageAttachActive"
      :enable-attach="enableAttach"
      :toggle-attach-button="isImageAttachActive">
      <div slot="text-content">
        <vue-email v-if="isEmailModalActive"
          v-show="!isImageAttachActive"
          ref="emailer"
          v-model="emailer"
          :report-bytes="reportBytes"
          :report-name="`${emailer.reportName}.${reportExtension}`"
          :report-content-type="reportContentType"
          :quote-image-attachments="selectedImages"
          @new-email="newEmailInput"
          @sent="sent"
          :asset-id="emailer.assetId" />
        <image-select v-if="emailer && isImageAttachActive"
          v-show="isImageAttachActive"
          v-model="selectedImages"
          :quote-id="emailer.assetId"
          :quote-ids="quoteIds" />
      </div>
    </vue-email-modal>
  </div>
</template>

<script>
import PrintPreviewAppDetailHeader from './PrintPreviewAppDetailHeader'
import PrintPreviewService from './PrintPreviewService'
import PrintPreviewExportModal from './PrintPreviewExportModal'
import { ReportFormatTypes } from '@/enums'
import ImageSelect from '@/components/ImageSelect'
import { VueEmail, VueEmailModal } from '@/components/VueEmail'
import { Emailer } from '@/classes'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import mimeTypes from 'mime-types'
import Guid from '@/components/Guid'
import StoreUtil from '@/store/utils'
import _isEmpty from 'lodash/isEmpty'
// import { isBrowser } from '@/components/utils/helpers'
// import Base64ArrayBuffer from '@/components/utils/Base64ArrayBuffer'

// Temporary const for copyright message
const divider = '=============================================================='
const copyright =
  'All accompanying materials are the exclusive property of [COMPANY].  All [COMPANY] rights are reserved.  These Materials must not be reproduced, adapted, modified, published, or communicated to any person in any form, without express prior written consent of [COMPANY].  Any unauthorised use of these Materials will be vigorously pursued and may result in legal action, including a claim for damages or an account for loss of profits.'

export default {
  name: 'PrintPreview',
  components: {
    PrintPreviewAppDetailHeader,
    PrintPreviewExportModal,
    VueEmail,
    VueEmailModal,
    ImageSelect
  },
  mixins: [StoreMixin],
  props: {
    reportName: {
      type: String,
      default: '',
      required: true
    },
    quoteIds: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      pdfBase64: '',
      pdfUrl: '',
      reportBytes: null,
      reportContentType: '',
      isExportModalActive: false,
      toolbarHeight: 0,
      isEmailModalActive: false,
      toEmails: [],
      ccEmails: [],
      bccEmails: [],
      emailer: null,
      isEnteringNewEmail: false,
      isNewBrowserTab: this.$route.query.isNewTab || false,
      isImageAttachActive: false,
      selectedImageIds: [],
      // images: [],
      inputTagList: [],
      selectedImages: [],
      parameterId: this.$route.query.parameterId,
      parameters: null,
      cacheKey: ''
    }
  },
  computed: {
    reportExtension() {
      return mimeTypes.extension(this.reportContentType)
    },
    enableSend() {
      return !this.isEnteringNewEmail
    },
    enableAttach() {
      return (
        this.reportName === 'rptQuoteDollarHour' ||
        this.reportName === 'rptQuoteDollarOnly' ||
        this.reportName === 'rptQuoteDollarHourAdditionals' ||
        this.reportName === 'rptQuoteDollarOnlyAdditionals' ||
        this.reportName === 'rptPartsEnquiry' ||
        this.reportName.startsWith('rptInvoice')
      )
    }
  },
  watch: {
    $userInfo: function(newVal, oldVal) {
      console.log('watch $userInfo', oldVal)
      if (oldVal === null) {
        this.resetEmailer()
        // this.loadReport()
      }
    },
    'emailer.attachments': {
      handler: function(newVal, oldVal) {
        if (this.selectedImages.length > 0) {
          const difference = this.selectedImages.filter(function(image) {
            const index = newVal.findIndex(i => i === image.fileName)
            return index === -1
          })
          if (!_isEmpty(difference)) {
            const imageIndex = this.selectedImages.findIndex(i => i.fileName === difference[0].fileName)
            this.selectedImages.splice(imageIndex, 1)
            const idIndex = this.selectedImageIds.findIndex(id => id === difference[0].quoteImageId)
            this.selectedImageIds.splice(idIndex, 1, Guid.empty())
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.clearSessionStorage()
  },
  mounted() {
    this.resetEmailer()
    this.loadReport()
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case 'exportreport':
          this.showExportModal()
          break
        case 'email':
          this.email()
          break
        case 'print':
          this.print()
          break
        case 'back':
          // this.removeEmailer(this.$route.query.emailerId)
          this.$router.go(-1)
          break
      }
    },
    print() {},
    email() {
      this.isEmailModalActive = true
    },
    async exportReport(format) {
      this.$showSpinner()
      this.isExportModalActive = false
      await PrintPreviewService.exportReportEx(this.$route.params.reportName, format, this.parameters)
      this.exportFormat = ''
      this.$hideSpinner()
    },
    showExportModal(format) {
      this.isExportModalActive = true
    },
    closeExportModal() {
      this.isExportModalActive = false
    },
    async loadReport() {
      this.$showSpinner()
      this.resetReportParameters()
      // console.log(this.parameters)
      // this.cacheKey = await PrintPreviewService.cacheParameters(this.parameters)
      // console.log(this.cacheKey)
      // const result = await PrintPreviewService.getReportDataEx(this.$route.params.reportName, ReportFormatTypes.pdf, this.cacheKey)
      const result = await PrintPreviewService.postReportData(this.$route.params.reportName, ReportFormatTypes.pdf, this.parameters)
      this.reportBytes = result.data
      this.reportContentType = result.headers['content-type'].toLowerCase()
      const pdfBlob = new Blob([result.data], { type: this.reportContentType })
      this.pdfUrl = URL.createObjectURL(pdfBlob)

      // Resize PDF viewer to fit
      const mainNav = document.getElementById('main-nav-menu')
      const appHeader = document.getElementById('app-detail-header')
      const pdfContainer = document.getElementById('pdf-container')
      this.toolbarHeight = mainNav.offsetHeight + appHeader.offsetHeight
      pdfContainer.style.height = window.innerHeight - mainNav.offsetHeight - appHeader.offsetHeight
      this.updateAttachmentList()
      this.$hideSpinner()
    },
    async sendEmail() {
      this.$refs.emailer.send()
      // this.discardEmail()
      // this.resetEmailer()
      // this.isEmailModalActive = false
    },
    discardEmail() {
      this.isEmailModalActive = false
      this.isImageAttachActive = false
      this.selectedImageIds.splice(0, this.selectedImageIds.length)
      this.selectedImages.splice(0, this.selectedImages.length)
      this.resetEmailer()
    },
    attachMedia() {},
    async resetEmailer() {
      const sessionEmailer = this.retrieveEmailer() || StoreUtil.getLocalStorage(this.$route.query.emailerId, 'emailer')
      // console.log(sessionEmailer)
      if (sessionEmailer) {
        this.emailer = sessionEmailer
      } else {
        this.emailer = _cloneDeep(this.getEmailer(this.$route.query.emailerId) || new Emailer())
      }
      this.emailer.message = `\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      StoreUtil.removeLocalStorage(this.$route.query.emailerId, 'emailer')
      this.persistEmailer()
      this.updateAttachmentList()
    },
    retrieveEmailer() {
      return JSON.parse(sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`))
    },
    persistEmailer() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`, JSON.stringify(this.emailer))
    },
    clearSessionStorage() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`)
    },
    async sent() {
      // 'sent' event from VueEmail component. Create email remark for asset e.g. QuickInvoice etc
      if (this.emailer.assetId && this.emailer.assetId !== Guid.empty()) {
        const remarkText = `${this.emailer.remark} has been emailed to ${this.emailer.toEmails.join(', ')}`
        const remark = {
          remarkId: Guid.empty(),
          assetId: this.emailer.assetId,
          remarks: remarkText,
          remarkTypeId: this.emailer.remarkTypeId
        }
        // console.log(remark)
        PrintPreviewService.postRemark(remark)

        if (`${this.emailer.subject}`.substring(0, 14) === 'Purchase Order') {
          let quoteId = await PrintPreviewService.getQuote(this.emailer.assetId)
          if (quoteId !== Guid.empty()) {
            const quoteRemark = {
              remarkId: Guid.empty(),
              assetId: quoteId,
              remarks: remarkText,
              remarkTypeId: this.emailer.remarkTypeId
            }
            PrintPreviewService.postRemark(quoteRemark)
          }
        }
      }
      this.discardEmail()
    },
    newEmailInput(newEmail) {
      this.isEnteringNewEmail = newEmail
    },
    async showAttachImage() {
      this.isImageAttachActive = !this.isImageAttachActive
      if (!this.isImageAttachActive) {
        this.updateAttachmentList()
      }
    },
    updateAttachmentList(imagesForSend) {
      this.emailer.attachments.splice(0, this.emailer.attachments.length)
      this.emailer.attachments.splice(this.emailer.attachments.length, 1, `${this.emailer.reportName}.${this.reportExtension}`)
      for (var i = 0; i < this.selectedImages.length; i++) {
        this.emailer.attachments.splice(this.emailer.attachments.length, 1, this.selectedImages[i].fileName)
      }
    },
    retrieveParameters() {
      const json = sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`)
      return json ? JSON.parse(json) : ''
    },
    async resetReportParameters() {
      const sessionParameters = this.retrieveParameters() || StoreUtil.getLocalStorage(this.$route.query.parameterId, this.$route.meta.pkey)
      // console.log('sessionParameters', sessionParameters)
      if (sessionParameters) {
        this.parameters = sessionParameters
      } else {
        this.parameters = _cloneDeep(this.reportParameters)
      }
      // console.log(this.parameters, this.reportParameters)
      StoreUtil.removeLocalStorage(this.$route.query.parameterId, this.$route.meta.pkey)
      this.persistParameters()
    },
    persistParameters() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`, JSON.stringify(this.parameters))
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.clearSessionStorage()
    this.removeEmailer(this.$route.query.emailerId)
    this.removeParameters(this.$route.query.parameterId)
    next()
  }
}
</script>

<style lang="scss" scoped>
object.pdf-document,
embeb.pdf-document {
  width: 100% !important;
  height: 100% !important;
}
</style>
