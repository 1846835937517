<template>
  <div>
    <div id="pdf-container"
      name="pdf-container"
      :style="`height: calc(93vh - ${toolbarHeight}px); overflow: hidden;`">
      <object id="pdfDoc"
        class="pdf-document"
        :data="pdfUrl"
        type="application/pdf"
        style="width: 100%; height: 100%;">
        <embed :src="pdfUrl"
          class="pdf-document"
          type="application/pdf">
      </object>
    </div>
  </div>
</template>

<script>
import StoreUtil from '@/store/utils'
import PrintPreviewService from './PrintPreviewService'
import { ReportFormatTypes } from '@/enums'
import StoreMixin from './storeMixin'
import HttpStatus from '@/components/http-status'

export default {
  name: 'PrintPreviewWithoutHeader',
  components: {},
  mixins: [StoreMixin],
  props: {
    reportName: {
      type: String,
      default: '',
      required: true
    },
    parameters: {
      type: Array,
      required: true
    },
    parameterId: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      pdfBase64: '',
      pdfUrl: '',
      reportBytes: null,
      reportContentType: '',
      toolbarHeight: 0
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.loadReport()
  },
  methods: {
    async loadReport() {
      this.$showSpinner()
      this.resetReportParameters()
      this.cacheKey = await PrintPreviewService.cacheParameters(this.parameters)
      const result = await PrintPreviewService.postReportData(this.reportName, ReportFormatTypes.pdf, this.parameters)
      if (result && result.status === HttpStatus.OK) {
        this.$emit('loadFinish')
      }
      this.reportBytes = result.data
      this.reportContentType = result.headers['content-type'].toLowerCase()
      const pdfBlob = new Blob([result.data], { type: this.reportContentType })
      this.pdfUrl = URL.createObjectURL(pdfBlob)
      // Resize PDF viewer to fit
      //const mainNav = document.getElementById('main-nav-menu')
      //const appHeader = document.getElementById('app-detail-header')
      //const pdfContainer = document.getElementById('pdf-container')
      //this.toolbarHeight = mainNav.offsetHeight + appHeader.offsetHeight
      //pdfContainer.style.height = window.innerHeight - mainNav.offsetHeight - appHeader.offsetHeight
      //this.updateAttachmentList()
      this.$hideSpinner()
    },
    async resetReportParameters() {
      StoreUtil.removeLocalStorage(this.parameterId, this.$route.meta.pkey)
      this.persistParameters()
    },
    persistParameters() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`, JSON.stringify(this.parameters))
    }
  }
}
</script>