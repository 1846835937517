<template>
  <div>
    <print-preview-app-detail-header :show-toggle="true"
      :show-print="false"
      :show-back="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <slot name="side-bar" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div id="pdf-container"
              name="pdf-container"
              :style="`height: calc(93vh - ${toolbarHeight}px); overflow: hidden;`">
              <object id="pdfDoc"
                :data="pdfUrl"
                type="application/pdf"
                style="width: 100%; height: 100%;" />
            </div>
          </article>
        </div>
      </div>
    </div>
    <print-preview-export-modal v-if="isExportModalActive"
      :active.sync="isExportModalActive"
      @exportReport="exportReport"
      @close="closeExportModal" />
    <vue-email-modal v-if="isEmailModalActive"
      :active.sync="isEmailModalActive"
      icon-name="mdi-email-open"
      icon-type="primary"
      @send="sendEmail"
      @discard="discardEmail"
      @attach="attachMedia"
      @show-attach="showAttachImage"
      :enable-send="enableSend && !isImageAttachActive"
      :enable-attach="enableAttach"
      :toggle-attach-button="isImageAttachActive">
      <div slot="text-content">
        <vue-email v-if="isEmailModalActive"
          v-show="!isImageAttachActive"
          ref="emailer"
          v-model="emailer"
          :report-bytes="reportBytes"
          :report-name="`${emailer.reportName}.${reportExtension}`"
          :report-content-type="reportContentType"
          :quote-image-attachments="images"
          @new-email="newEmailInput"
          @sent="sent"
          :asset-id="emailer.assetId" />
        <image-select v-if="emailer && isImageAttachActive"
          v-show="isImageAttachActive"
          v-model="selectedImageIds"
          :quote-id="emailer.assetId" />
      </div>
    </vue-email-modal>
  </div>
</template>

<script>
import PrintPreviewAppDetailHeader from './PrintPreviewAppDetailHeader'
import PrintPreviewService from './PrintPreviewService'
import PrintPreviewExportModal from './PrintPreviewExportModal'
import { ReportFormatTypes } from '@/enums'
import ImageSelect from '@/components/ImageSelect'
import { VueEmail, VueEmailModal } from '@/components/VueEmail'
import StoreMixin from './storeMixin'
import mimeTypes from 'mime-types'
import Guid from '@/components/Guid'
import StoreUtil from '@/store/utils'
import _isEmpty from 'lodash/isEmpty'

// Temporary const for copyright message
const divider = '=============================================================='
const copyright =
  'All accompanying materials are the exclusive property of [COMPANY].  All [COMPANY] rights are reserved.  These Materials must not be reproduced, adapted, modified, published, or communicated to any person in any form, without express prior written consent of [COMPANY].  Any unauthorised use of these Materials will be vigorously pursued and may result in legal action, including a claim for damages or an account for loss of profits.'

export default {
  name: 'PrintPreviewWithSideView',
  components: {
    PrintPreviewAppDetailHeader,
    PrintPreviewExportModal,
    VueEmail,
    VueEmailModal,
    ImageSelect
  },
  mixins: [StoreMixin],
  props: {
    reportName: {
      type: String,
      default: '',
      required: true
    },
    emailer: {
      type: [Object],
      required: true
    },
    parameters: {
      type: Array,
      required: true
    },
    parameterId: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      pdfBase64: '',
      reportBytes: null,
      reportContentType: '',
      isExportModalActive: false,
      pdfUrl: '',
      toolbarHeight: 0,
      isEmailModalActive: false,
      toEmails: [],
      ccEmails: [],
      bccEmails: [],
      isEnteringNewEmail: false,
      isNewBrowserTab: this.$route.query.isNewTab || false,
      isImageAttachActive: false,
      selectedImageIds: [],
      images: [],
      inputTagList: [],
      cacheKey: ''
    }
  },
  computed: {
    reportExtension() {
      return mimeTypes.extension(this.reportContentType)
    },
    enableSend() {
      return this.emailer.toEmails.length > 0 && !this.isEnteringNewEmail
    },
    enableAttach() {
      return this.reportName === 'rptQuoteDollarHour' || this.reportName === 'rptQuoteDollarOnly'
    }
  },
  watch: {
    reportName: {
      deep: true,
      handler() {
        this.loadReport()
      }
    },
    $userInfo: function(newVal, oldVal) {
      console.log('watch $userInfo', oldVal)
      if (oldVal === null) {
        this.resetEmailer()
      }
    },
    'emailer.attachments': {
      handler: function(newVal, oldVal) {
        if (this.images.length > 0) {
          const difference = this.images.filter(function(image) {
            const index = newVal.findIndex(i => i === image.fileName)
            return index === -1
          })
          if (!_isEmpty(difference)) {
            const imageIndex = this.images.findIndex(i => i.fileName === difference[0].fileName)
            this.images.splice(imageIndex, 1)
            const idIndex = this.selectedImageIds.findIndex(id => id === difference[0].quoteImageId)
            this.selectedImageIds.splice(idIndex, 1, Guid.empty())
          }
        }
      },
      deep: true
    },
    selectedImageIds: {
      handler: function(newVal, oldVal) {
        // console.log(newVal)
        // this.updateAttachmentList(newVal)
      }
    }
  },
  created() {},
  mounted() {
    this.resetEmailer()
    this.loadReport()
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case 'exportreport':
          this.showExportModal()
          break
        case 'email':
          this.email()
          break
        case 'print':
          //this.print()
          break
        case 'back':
          this.$router.go(-1)
          break
      }
    },
    email() {
      this.isEmailModalActive = true
    },
    async exportReport(format) {
      this.$showSpinner()
      this.isExportModalActive = false
      await PrintPreviewService.exportReportEx(this.reportName, format, this.parameters)
      this.exportFormat = ''
      this.$hideSpinner()
    },
    showExportModal(format) {
      this.isExportModalActive = true
    },
    closeExportModal() {
      this.isExportModalActive = false
    },
    async loadReport() {
      this.$showSpinner()
      this.resetReportParameters()
      //console.log(this.parameters)
      this.cacheKey = await PrintPreviewService.cacheParameters(this.parameters)
      //console.log(this.cacheKey)
      const result = await PrintPreviewService.postReportData(this.reportName, ReportFormatTypes.pdf, this.parameters)
      this.reportBytes = result.data
      this.reportContentType = result.headers['content-type'].toLowerCase()
      const pdfBlob = new Blob([result.data], { type: this.reportContentType })
      this.pdfUrl = URL.createObjectURL(pdfBlob)
      // Resize PDF viewer to fit
      const mainNav = document.getElementById('main-nav-menu')
      const appHeader = document.getElementById('app-detail-header')
      const pdfContainer = document.getElementById('pdf-container')
      this.toolbarHeight = mainNav.offsetHeight + appHeader.offsetHeight
      pdfContainer.style.height = window.innerHeight - mainNav.offsetHeight - appHeader.offsetHeight
      this.updateAttachmentList()
      this.$hideSpinner()
    },
    async sendEmail() {
      await this.$refs.emailer.send()
      this.resetEmailer()
      this.isEmailModalActive = false
    },
    discardEmail() {
      this.isEmailModalActive = false
      this.isImageAttachActive = false
      this.selectedImageIds.splice(0, this.selectedImageIds.length)
      this.images.splice(0, this.images.length)
      this.resetEmailer()
    },
    attachMedia() {},
    async resetEmailer() {
      this.emailer.message = `\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      StoreUtil.removeLocalStorage(this.$route.query.emailerId, 'emailer')
      this.persistEmailer()
      this.updateAttachmentList()
    },
    retrieveEmailer() {
      return JSON.parse(sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`))
    },
    persistEmailer() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`, JSON.stringify(this.emailer))
    },
    clearSessionStorage() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`)
    },
    sent() {
      // 'sent' event from VueEmail component. Create email remark for asset e.g. QuickInvoice etc
      if (this.emailer.assetId && this.emailer.assetId !== Guid.empty()) {
        const remarkText = `${this.emailer.remark} has been emailed to ${this.emailer.toEmails.join(', ')}`
        const remark = {
          remarkId: Guid.empty(),
          assetId: this.emailer.assetId,
          remarks: remarkText,
          remarkTypeId: this.emailer.remarkTypeId
        }
        // console.log(remark)
        PrintPreviewService.postRemark(remark)
      }
    },
    newEmailInput(newEmail) {
      this.isEnteringNewEmail = newEmail
    },
    async showAttachImage() {
      this.isImageAttachActive = !this.isImageAttachActive
      if (!this.isImageAttachActive) {
        const vm = this
        this.images = new Array(this.selectedImageIds.filter(id => id !== Guid.empty()).length)
        const promises = this.selectedImageIds
          .filter(id => id !== Guid.empty())
          .map(async function(id, index) {
            const image = await PrintPreviewService.getImageDetail(id)
            vm.images.splice(index, 1, image)
          })
        await Promise.all(promises)
        this.updateAttachmentList()
      }
    },
    updateAttachmentList(imagesForSend) {
      this.emailer.attachments.splice(0, this.emailer.attachments.length)
      this.emailer.attachments.splice(this.emailer.attachments.length, 1, `${this.emailer.reportName}.${this.reportExtension}`)
      for (var i = 0; i < this.images.length; i++) {
        this.emailer.attachments.splice(this.emailer.attachments.length, 1, this.images[i].fileName)
      }
    },
    retrieveParameters() {
      const json = sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`)
      return json ? JSON.parse(json) : ''
    },
    async resetReportParameters() {
      StoreUtil.removeLocalStorage(this.parameterId, this.$route.meta.pkey)
      this.persistParameters()
    },
    persistParameters() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`, JSON.stringify(this.parameters))
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.clearSessionStorage()
    next()
  }
}
</script>
